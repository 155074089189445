/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

export function isCheckAddress(str) {
  return /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/.test(str)
}

export function isCheckPhoneNum(str) {
  return /^((\d{3}-\d{8}|\d{4}-\d{7,8})|(1[3|5|7|8][0-9]{9}))$/.test(str)
}

export function isBankNumber(str) {
  return /^\d{1,50}$/.test(str)
}

export function isIdentifyNumber(str) {
  return /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/.test(str)
}

// 发票抬头
export function isInvoiceTitle(str) {
  return /^[0-9a-zA-Z\(\)\（\）\u4e00-\u9fa5]{0,50}$/.test(str)
}

