<!-- 发票 -->
<template>
  <div class="invoice">
    <headers ref="headers" :headname="headName"></headers>
    <div class="invoice_list">
      <el-form class="form">
        <el-form-item label="订单编号" :label-width="formLabelWidth">
          <el-input v-model="form_model.order_sn" class="input" placeholder="请填写订单编号" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="发票类型" :label-width="formLabelWidth">
          <el-select v-model="form_model.invoice_type" class="input" placeholder="" @change="invoiceTypeChange()">
            <el-option
              v-for="(item,index) in invoiceType"
              :key="index"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发票内容" :label-width="formLabelWidth">
          <el-radio-group v-model="form_model.invoice_content">
            <el-radio
              v-for="(item,index) in contentList"
              :key="index"
              :label="item.id"
            >{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="抬头类型" :label-width="formLabelWidth">
          <!-- <span v-if="form_model.invoice_type === 3" class="input">单位</span> -->
          <el-input v-if="form_model.invoice_type === 3 || form_model.invoice_type === 5" v-model="title" class="input" :disabled="true"></el-input>
          <el-radio-group v-else v-model="form_model.title_type" @change="titleTypeChange()">
            <el-radio :label="0">个人</el-radio>
            <el-radio :label="1">单位</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="((form_model.invoice_type === 1 || form_model.invoice_type === 2 ||form_model.invoice_type == 4) && form_model.title_type === 1)">
          <span class="inp">*</span>
          <el-form-item label="单位名称" :label-width="formLabelWidth">
            <el-input v-model="form_model.invoice_title" class="input" placeholder="请填写单位名称"></el-input>
          </el-form-item>
          <span class="inp">*</span>
          <el-form-item label="税号" :label-width="formLabelWidth">
            <el-input v-model="form_model.taxpayer_identifier" class="input" placeholder="请填写税号"></el-input>
          </el-form-item>
          <el-form-item label="注册地址" :label-width="formLabelWidth">
            <el-input v-model="form_model.reg_address" class="input" placeholder="请填写注册地址"></el-input>
          </el-form-item>
          <el-form-item label="注册电话" :label-width="formLabelWidth">
            <el-input v-model="form_model.reg_tel" class="input" placeholder="请填写注册电话"></el-input>
          </el-form-item>
          <el-form-item label="开户银行" :label-width="formLabelWidth">
            <el-input v-model="form_model.bank_name" class="input" placeholder="请填写开户银行"></el-input>
          </el-form-item>
          <el-form-item label="银行账号" :label-width="formLabelWidth">
            <el-input v-model="form_model.bank_account" class="input" placeholder="请填写银行账号"></el-input>
          </el-form-item>
        </div>
        <div v-else-if="form_model.invoice_type === 3 || form_model.invoice_type === 5">
          <span class="inp">*</span>
          <el-form-item label="单位名称" :label-width="formLabelWidth">
            <el-input v-model="form_model.invoice_title" class="input" placeholder="请填写单位名称"></el-input>
          </el-form-item>
          <span class="inp">*</span>
          <el-form-item label="纳税人识别码" :label-width="formLabelWidth">
            <el-input v-model="form_model.taxpayer_identifier" class="input" placeholder="请填写纳税人识别码"></el-input>
          </el-form-item>
          <span class="inp">*</span>
          <el-form-item label="注册地址" :label-width="formLabelWidth">
            <el-input v-model="form_model.reg_address" class="input" placeholder="请填写注册地址"></el-input>
          </el-form-item>
          <span class="inp">*</span>
          <el-form-item label="注册电话" :label-width="formLabelWidth">
            <el-input v-model="form_model.reg_tel" class="input" placeholder="请填写注册电话"></el-input>
          </el-form-item>
          <span class="inp">*</span>
          <el-form-item label="开户银行" :label-width="formLabelWidth">
            <el-input v-model="form_model.bank_name" class="input" placeholder="请填写开户银行"></el-input>
          </el-form-item>
          <span class="inp">*</span>
          <el-form-item label="银行账号" :label-width="formLabelWidth">
            <el-input v-model="form_model.bank_account" class="input" placeholder="请填写银行账号"></el-input>
          </el-form-item>
        </div>
        <span class="inp">*</span>
        <el-form-item label="手机号码" :label-width="formLabelWidth">
          <el-input v-model="form_model.mobile" class="input" placeholder="请填写手机号码"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input v-model="form_model.remark" rows="5" type="textarea" maxlength="110" resize="none" class="input" placeholder="请填写备注(不超过110个字)"></el-input>
        </el-form-item>
        <el-button class="formBtn" type="primary" @click="submit()">提交</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import headers from '@/components/topTitle/index.vue'
import { InvoiceSetInfo, SaveInvoice } from '@/api/center.js'
import { isCheckPhoneNum, isBankNumber, isIdentifyNumber } from '@/utils/validate.js'
export default {
  components: { headers },
  data() {
    return {
      headName: '开发票',
      formLabelWidth: '110px',
      title: '单位',
      form_model: {
        order_sn: '',
        invoice_type: '',
        invoice_content: '',
        title_type: 0,
        invoice_title: '',
        taxpayer_identifier: '',
        reg_address: '',
        reg_tel: '',
        bank_name: '',
        bank_account: '',
        mobile: '',
        remark: ''
      },
      contentList: [],
      invoiceType: []
    }
  },

  computed: {},

  created() {
    this.form_model.order_sn = this.$route.query.order_sn
    this.getInvoiceSetInfo()
  },

  methods: {
    getInvoiceSetInfo() {
      const info = {
        order_sn: this.form_model.order_sn
      }
      InvoiceSetInfo(info).then(res => {
        this.invoiceType = res.result.type
        // this.contentList = res.result.content
        this.form_model.invoice_type = this.invoiceType ? this.invoiceType[0].id : ''
        if (this.form_model.invoice_type == 1 || this.form_model.invoice_type == 2 || this.form_model.invoice_type == 4) {
          this.titleTypeChange()
        }
        // this.form_model.invoice_content = this.contentList ? this.contentList[0].id : ''
        this.contentList = this.form_model.invoice_type ? this.invoiceType[this.invoiceType.findIndex(i => i.id == this.form_model.invoice_type)].content : ''
        this.form_model.invoice_content = this.form_model.invoice_type ? this.invoiceType[this.invoiceType.findIndex(i => i.id == this.form_model.invoice_type)].content[0].id : ''
      })
    },
    invoiceTypeChange() {
      this.form_model.title_type = this.form_model.invoice_type === 3 || this.form_model.invoice_type === 5 ? 1 : this.form_model.title_type
      this.contentList = this.invoiceType[this.invoiceType.findIndex(i => i.id == this.form_model.invoice_type)].content
      this.form_model.invoice_title = ''
      this.form_model.taxpayer_identifier = ''
      this.form_model.reg_address = ''
      this.form_model.reg_tel = ''
      this.form_model.bank_name = ''
      this.form_model.bank_account = ''
    },
    titleTypeChange() {
      this.form_model.invoice_title = ''
      this.form_model.taxpayer_identifier = ''
      if (this.form_model.title_type === 0) {
        this.form_model.invoice_title = '个人'
      }
    },

    submit() {
      const formData = this.form_model
      if (formData.invoice_type === 3 || formData.invoice_type === 5) {
        // 增值税
        if (!formData.invoice_title.length || !formData.taxpayer_identifier.length || !formData.reg_address.length ||
          !formData.reg_tel.length || !formData.bank_name.length || !formData.bank_account.length
        ) {
          return this.$message.error('请补全信息')
        } else {
          if (formData.invoice_title.length == 0) {
            return this.$message.error('请输入单位名称')
          }
          if (!isIdentifyNumber(formData.taxpayer_identifier)) {
            return this.$message.error('请输入正确的纳税人识别码')
          }
          if (!isCheckPhoneNum(formData.reg_tel)) {
            return this.$message.error('请输入正确的注册电话')
          }
          if (formData.reg_address.length == 0) {
            return this.$message.error('请输入注册地址')
          }
          if (!isBankNumber(formData.bank_account)) {
            return this.$message.error('请输入正确的银行账号')
          }
        }
      } else {
        if (formData.title_type === 1) {
          if ((!formData.invoice_title.length || !formData.taxpayer_identifier.length)) {
            return this.$message.error('请补全信息')
          } else {
            if (!isIdentifyNumber(formData.taxpayer_identifier)) {
              return this.$message.error('请输入正确的税号')
            }
            if (formData.invoice_title.length == 0) {
              return this.$message.error('请输入单位名称')
            }
          }
        }
      }
      if (!formData.mobile.length) {
        return this.$message.error('请补全信息')
      // } else if (formData.mobile.length !== 11) {
      //   return this.$message.error('手机号格式不对')
      }
      SaveInvoice(this.form_model).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          // 开成功
          const result = res.result ? res.result : {}
          const invoice_type = result.invoice_type
          if (invoice_type === 1 || invoice_type === 4 || invoice_type === 5) {
            // 普通电子
            this.$router.go(-1)
          } else {
            const pay_url = result.pay_url
            window.open(pay_url)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.invoice{
  width: 965px;
  padding: 0 0 20px 0;
	min-height: 900px;
  // background-color: aqua;
}
.invoice_list{
  width: calc(100% - 60px);
  height: 100%;
  margin: 20px 30px 0 30px;
  // background-color: bisque;
}
.form{
  text-align: left;
  /deep/.el-form-item__label{
    text-align: left;
  }
  /deep/.el-form-item{
    margin-bottom: 10px;
  }
}
.input{
  width: 300px;
}
.formBtn{
  width: 100px;
  margin-top: 15px;
}

.inp{
  color: #ee2e2e;
  position: absolute;
  margin-left: -8px;
  margin-top: 8px;
}
// /deep/.el-form-item__label:before{
//   content: '*';
//     color: #F56C6C;
//     margin-right: 4px;
// }
</style>
